import React, { useEffect } from 'react'
import gsap from 'gsap'

function Value({ value: { name, value } }) {
    return (
        <>
            <div className='carousel--title'>
                <div className='small-serif orange '>{name}</div>
            </div>
            <div className='carousel--item--inner flex flex-c al-i-end'>

                <h1 className='h1a nowrap text-suede align--title--carousel'>{value}</h1>
            </div>
        </>
    )
}

function ValuesItem({values}) {
    return (
        <div className='carousel--item flex'>
            {values.map((value,index) => {
                return <Value key={index} value={value} />
            })}
        </div>
    )
}

export default function Carousel({ values }) {

    useEffect(() =>{
        if(window.innerWidth < 768){
            const tl = gsap.timeline({repeat:-1})
            
            tl.to('.carousel--wrapper',{x:'-33.33%',duration:50,ease:'linear'})
            tl.to('.carousel--wrapper',{x:'-66.66%',duration:50,ease:'linear'}) 
        }else{
            const tl = gsap.timeline({repeat:-1})
            
            tl.to('.carousel--wrapper',{x:'-33.33%',duration:50,ease:'linear'})
            tl.to('.carousel--wrapper',{x:'-66.66%',duration:50,ease:'linear'}) 
        }
      
           
    },[])
    return (
        <div className='carousel--wrapper'>
          <ValuesItem key={1} values={values}/>
          <ValuesItem key={4} values={values}/>
          <ValuesItem  key={2} values={values}/>
          <ValuesItem  key={3} values={values}/>
        </div>
    )
}

 