import React, {memo} from "react"

import "../scss/main.scss"
import 'swiper/css';
import Footer from "./footer"
import Header from "./header"



const Layout = ({ children }) => {
  return (
    <>
        <Header />
  
      <div id='content' className="mh-50 ">
        {children}
      </div>
      <Footer />
    </>
  )
}



export default memo(Layout,() => true)
