import React from 'react';

export default function Up({ hoverState = true }) {
  return (
    <svg className={['arrows ', (hoverState ? 'hover--true' : 'hovered'),].join(' ')} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className='circle' r="23.5" transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 24 24)" stroke="#E0DBC7" strokeOpacity="0.2" />
      <mask id="path-2-inside-1_351_9" fill="white">
        <path d="M16.8163 26.7867L17.553 27.5234L23.725 21.3375C23.7611 21.3105 23.805 21.2958 23.8501 21.2958C23.8952 21.2958 23.9391 21.3105 23.9752 21.3375L30.0221 27.3844L30.7588 26.6477L24.712 20.5869C24.4828 20.3594 24.173 20.2317 23.8501 20.2317C23.5272 20.2317 23.2174 20.3594 22.9883 20.5869L16.8163 26.7867Z" />
      </mask>
      <path className='arrow' d="M16.8163 26.7867L15.8333 27.7696L14.8527 26.7889L15.8311 25.806L16.8163 26.7867ZM17.553 27.5234L18.537 28.5053L17.5541 29.4904L16.57 28.5063L17.553 27.5234ZM23.725 21.3375L22.7409 20.3557L22.8113 20.2852L22.8909 20.2255L23.725 21.3375ZM23.8501 21.2958L23.8501 19.9058L23.8501 21.2958ZM23.9752 21.3375L24.8092 20.2255L24.8883 20.2848L24.9581 20.3546L23.9752 21.3375ZM30.0221 27.3844L31.0051 28.3673L30.0221 29.3503L29.0392 28.3674L30.0221 27.3844ZM30.7588 26.6477L31.7429 25.6659L32.7235 26.6488L31.7418 27.6306L30.7588 26.6477ZM24.712 20.5869L25.6913 19.6004L25.696 19.6051L24.712 20.5869ZM22.9883 20.5869L22.0031 19.6061L22.0089 19.6004L22.9883 20.5869ZM17.7993 25.8038L18.536 26.5405L16.57 28.5063L15.8333 27.7696L17.7993 25.8038ZM16.569 26.5416L22.7409 20.3557L24.709 22.3194L18.537 28.5053L16.569 26.5416ZM22.8909 20.2255C23.1679 20.0178 23.5045 19.9058 23.8501 19.9058L23.8501 22.6859C24.1055 22.6859 24.3543 22.6032 24.559 22.4496L22.8909 20.2255ZM23.8501 19.9058C24.1963 19.9058 24.5327 20.0181 24.8092 20.2255L23.1411 22.4496C23.3454 22.6028 23.5942 22.6859 23.8501 22.6859L23.8501 19.9058ZM24.9581 20.3546L31.005 26.4015L29.0392 28.3674L22.9922 22.3205L24.9581 20.3546ZM29.0391 26.4015L29.7758 25.6648L31.7418 27.6306L31.0051 28.3673L29.0391 26.4015ZM29.7747 27.6295L23.7279 21.5687L25.696 19.6051L31.7429 25.6659L29.7747 27.6295ZM23.7326 21.5734C23.7638 21.6044 23.8061 21.6218 23.8501 21.6218L23.8501 18.8416C24.5399 18.8416 25.2018 19.1144 25.6913 19.6004L23.7326 21.5734ZM23.8501 21.6218C23.8941 21.6218 23.9364 21.6044 23.9676 21.5734L22.0089 19.6004C22.4985 19.1144 23.1603 18.8416 23.8501 18.8416L23.8501 21.6218ZM23.9734 21.5676L17.8014 27.7674L15.8311 25.806L22.0031 19.6062L23.9734 21.5676Z" fill="#ff6326 " mask="url(#path-2-inside-1_351_9)" />
    </svg>);
}
