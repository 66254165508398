import React from 'react'

export default function LeftArrow({ hoverState = true, height = 48, width = 48 }) {
    return (
        <svg className={['arrows ', (hoverState ? 'hover--true' : 'hovered'),].join(' ')} width={width} height={height} viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle className='circle enquire--circle' cx="24" cy="24" r="23.5" />
            <mask id="path-2-inside-1_152_823" fill="white">
                <path d="M21.2133 31.1837L20.4766 30.447L26.6625 24.275C26.6895 24.2389 26.7042 24.195 26.7042 24.1499C26.7042 24.1048 26.6895 24.0609 26.6625 24.0248L20.6156 17.9779L21.3523 17.2412L27.4131 23.288C27.6406 23.5172 27.7683 23.827 27.7683 24.1499C27.7683 24.4728 27.6406 24.7826 27.4131 25.0117L21.2133 31.1837Z" />
            </mask>
            <path className='arrow' d="M21.2133 31.1837L20.2304 32.1667L21.2111 33.1473L22.194 32.1689L21.2133 31.1837ZM20.4766 30.447L19.4947 29.463L18.5096 30.4459L19.4937 31.43L20.4766 30.447ZM26.6625 24.275L27.6443 25.2591L27.7148 25.1887L27.7745 25.1091L26.6625 24.275ZM26.7042 24.1499H28.0942H26.7042ZM26.6625 24.0248L27.7745 23.1908L27.7152 23.1117L27.6454 23.0419L26.6625 24.0248ZM20.6156 17.9779L19.6327 16.9949L18.6497 17.9779L19.6326 18.9608L20.6156 17.9779ZM21.3523 17.2412L22.3341 16.2571L21.3512 15.2765L20.3694 16.2582L21.3523 17.2412ZM27.4131 23.288L28.3996 22.3087L28.3949 22.304L27.4131 23.288ZM27.4131 25.0117L28.3938 25.9969L28.3996 25.9911L27.4131 25.0117ZM22.1962 30.2007L21.4595 29.464L19.4937 31.43L20.2304 32.1667L22.1962 30.2007ZM21.4584 31.431L27.6443 25.2591L25.6806 23.291L19.4947 29.463L21.4584 31.431ZM27.7745 25.1091C27.9822 24.8321 28.0942 24.4955 28.0942 24.1499H25.3141C25.3141 23.8945 25.3968 23.6457 25.5504 23.441L27.7745 25.1091ZM28.0942 24.1499C28.0942 23.8037 27.9819 23.4673 27.7745 23.1908L25.5504 24.8589C25.3972 24.6546 25.3141 24.4058 25.3141 24.1499H28.0942ZM27.6454 23.0419L21.5985 16.995L19.6326 18.9608L25.6795 25.0078L27.6454 23.0419ZM21.5985 18.9609L22.3352 18.2242L20.3694 16.2582L19.6327 16.9949L21.5985 18.9609ZM20.3705 18.2253L26.4313 24.2721L28.3949 22.304L22.3341 16.2571L20.3705 18.2253ZM26.4266 24.2674C26.3956 24.2362 26.3782 24.1939 26.3782 24.1499H29.1583C29.1583 23.4601 28.8856 22.7982 28.3996 22.3087L26.4266 24.2674ZM26.3782 24.1499C26.3782 24.1059 26.3956 24.0636 26.4266 24.0324L28.3996 25.9911C28.8856 25.5015 29.1583 24.8397 29.1583 24.1499H26.3782ZM26.4324 24.0266L20.2326 30.1986L22.194 32.1689L28.3938 25.9969L26.4324 24.0266Z" fill="#ff6326 " mask="url(#path-2-inside-1_152_823)" />
        </svg>
    )
}
