import React from 'react'

export default function Facebook({to ='#'}) {
    return (
        <a href={to}>
        <svg className='hover--orange' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M11.6507 0H12.3642C15.5377 0.0963365 18.5445 1.44383 20.7294 3.74879C22.3216 5.44295 23.3828 7.56677 23.782 9.85785C23.8852 10.4021 23.958 10.9518 24 11.5042V12.476C23.8956 14.4283 23.3391 16.3295 22.3745 18.0297C20.838 20.5784 18.4401 22.4923 15.6151 23.4248C14.4852 23.751 13.321 23.944 12.1462 24H11.8687C8.65774 23.9269 5.60397 22.5949 3.36488 20.2909C1.18806 17.9644 -0.0113714 14.8894 0.014886 11.7025V11.5637C-0.00496202 11.4722 -0.00496202 11.3774 0.014886 11.2859C0.215498 9.30666 0.851853 7.39642 1.8782 5.69255C3.44477 3.21392 5.86291 1.39392 8.67729 0.575223C9.63636 0.244232 10.6374 0.0505636 11.6507 0ZM10.0252 7.65623V9.85785C10.0252 9.85785 10.0252 9.99672 9.82702 10.0761H8.61776V12.5554H9.82702C9.82702 12.5554 9.96573 12.5554 10.0252 12.7736V18.8827V19.2992H12.5427V12.7141C12.5427 12.595 12.5427 12.5554 12.6815 12.5554H14.6637C14.9102 11.8001 15.0307 11.0093 15.0205 10.2149V10.0562H12.6021C12.5831 10.0598 12.5636 10.0586 12.5451 10.0529C12.5266 10.0472 12.5099 10.0371 12.4962 10.0234C12.4826 10.0097 12.4724 9.9929 12.4667 9.97443C12.461 9.95596 12.4598 9.93636 12.4633 9.91735V8.2116C12.4633 7.47771 12.8995 7.08099 13.7518 7.00165H15.1593V4.87938C15.1593 4.74054 14.6637 4.66117 13.6726 4.66117H13.3157C12.6559 4.58196 11.9883 4.71895 11.4129 5.05153C10.8375 5.38411 10.3854 5.89434 10.1243 6.50578C10.0629 6.88627 10.0298 7.27083 10.0252 7.65623Z" fill="#C7B8A6"/>
        </svg>
        </a>
        
    )
}
