import React from 'react'
import Link from './link'
import FooterLogo from '../icons/footer-logo'
import ContentContainer from './content-container/content-container'
import Facebook from '../icons/footer/facebook'
import Instagram from '../icons/footer/instagram'
import Linkedin from '../icons/footer/linkedin'
import Carousel from './footer/carousel'
import Up from '../icons/footer/up'
import SmallSpacer from './spacer/small-spacer'
import Spacer from './spacer/spacer'




const values = [
    {
        name: 'Who',
        value: "We're beef cattle lot feeding experts"
    },
    {
        name: 'What',
        value: 'We custom feed cattle'
    },
    {
        name: 'why',
        value: "We help produce nourishing food for people"
    },
]


export default function Footer() {



    return (

        <footer className="bg-leather pt30 pb10">
            <ContentContainer>
                <div className=" ma flex footer--content">
                    <div className="w-50 m-hide">
                        <Link to='/'>
                            <div className="footer--logo"></div>
                        </Link>
                    </div>
                    <div className="blw1 w-50">
                        <div className='flex '>
                            <div className="menu">
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede" to="/">Home</Link>
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede" to="/about">About</Link>
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede" to="/operations">Operations</Link>
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede" to="/expertise">Expertise</Link>
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede" to="/team">Team</Link>
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede" to="/news">News</Link>
                                <Link activeClassName='blo1' className="h5 block pl30 text-suede  " to="/contact">Contact</Link>
                                <SmallSpacer />
                            </div>

                            <div className='flex flex-end flex-grow-1'>
                                <div>
                                    <button onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })}>
                                        <Up />
                                    </button>
                                </div>
                            </div>

                        </div>
                        <div className="spacer m-hide"></div>
                        <div className="small-spacer m-show"></div>
                        <div className="social flex pl30">
                            <div className='flex'>
                                <div className="icon mr10">
                                    <Facebook to='https://www.facebook.com/smithfieldcattleco/' />
                                </div>
                                <div className="icon mr10">
                                    <Instagram to='https://www.instagram.com/smithfield_cattle_company/?hl=en' />
                                </div>
                                <div className="icon">
                                    <Linkedin to='https://www.linkedin.com/company/smithfield-cattle-company/about/' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className=" ma flex mt60 btw1 bbw1 pt30 pb30 overflow-x-v ">
                    <Carousel values={values} />
                </div>
                <div className=" ma pt10 text-suede flex ju-sb">
                    <span className='m0 body-copy footer-small m-small'> © {new Date().getFullYear()} Copyright Smithfield Cattle Co.</span> <span className='body-copy footer-small m0 m-small'>Site by <a className='groundcrew' rel="noreferrer" target='_blank' href='https://groundcrew.com.au/'> GROUNDCREW </a></span>
                </div>
            </ContentContainer>
        </footer>

    )
}
