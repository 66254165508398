import React, { useEffect, useState, useCallback, useMemo, memo } from 'react'

import LeftArrow from '../icons/left-arrow'
import Logo from '../icons/logo'
import Link from './link'
import { throttle } from 'lodash'
import LogoCattle from '../icons/logo-cattle'
import LargeLogo from '../icons/large-logo'


const MenuButton = memo(({ onClick, open, className, transparent }) => {

    const classNames = useMemo(() => {
        return ['menu--button', (open ? 'open' : ''), className, (transparent ? "" : 'down')].join(' ')
    }, [className, transparent, open])

    return (
        <button className={classNames} onClick={onClick}>
            <div></div>
            <div className='middle--line'></div>
            <div></div>
        </button>
    )
})

const EnquireButton = memo(() => {
    return (
        <div>
            <Link to='/contact' className='enquire-shortcut '>
                <span className=' button-text'>Enquire</span>
                <LeftArrow height={26} width={26} />
            </Link>
        </div>
    )
})

const Menu = memo(({ open }) => {
    return (
        <div className={['menu--header ', (open ? 'open' : '')].join(' ')}>
            <div className='flex flex-c ju-c'>
                <Link activeClassName='leather' to='/' className='h3 bark mb10 text-center'>Home</Link>
                <Link activeClassName='leather' to='/about' className='h3 bark mb10 text-center'>About</Link>
                <Link activeClassName='leather' to='/operations' className='h3 bark mb10 text-center'>Operations</Link>
                <Link activeClassName='leather' to='/expertise' className='h3 bark mb10 text-center'>Expertise</Link>
                <Link activeClassName='leather' to='/team' className='h3 bark mb10 text-center'>Team</Link>
                <Link activeClassName='leather' to='/news' className='h3 bark mb10 text-center'>News</Link>
                <Link activeClassName='leather' to='/contact' className='h3 bark text-center'>Contact</Link>
            </div>

        </div>
    )
})

const Overlay = memo(({ onClick, open }) => {
    return (
        <button aria-label="overlay" className={(['overlay', (open ? 'open' : '')].join(' '))} onClick={onClick} />
    )
})


function useTransparencyController() {
    const [transparent, setTransparent] = useState(true)





    const throttledScroll = useCallback(() => {

        function greaterThan300(number) {
            return number >= 300;
        }

        function scrolledDistance(element) {
            return document.querySelector(element).scrollTop;
        }

        function toggleTransparent() {
            if (greaterThan300(scrolledDistance('html'))) {
                setTransparent(false)
            } else {
                setTransparent(true)
            }
        }
        return throttle(toggleTransparent, 1500)
    }, [])



    useEffect(() => {
        window.addEventListener('scroll', () => {
            throttledScroll()()
        })
        return () => window.removeEventListener('scroll', () => {
            throttledScroll()()
        })

    }, [throttledScroll])

    return transparent
}


const HeaderWrapper = memo(({ children }) => {
    return (
        <>
            <div className='pl30'>

            </div>
            <div>
                <Link to='/' >
                    {children}
                  
                </Link>
            </div>
            <div className='pr30'>
                <EnquireButton />
            </div>
        </>
    )
})

export default function Header() {

    const [open, setOpen] = useState(false)
    const transparent = useTransparencyController()
    return (
        <>
            <Menu open={open} />
            <MenuButton className='up' open={open} transparent={transparent} onClick={() => { setOpen((state) => !state) }} />
            <MenuButton className='sticky transparent' open={open} onClick={() => { setOpen((state) => !state) }} />
            <Overlay open={open} onClick={() => setOpen(false)} />
            <header className={'header up ' + (transparent ? '' : 'down')}>
                <HeaderWrapper className='up' >
                    <LogoCattle/>
                </HeaderWrapper>
            </header>
            <header className={'header  sticky transparent  white' + (transparent ? '' : 'down')}>
                <HeaderWrapper >
                    <div className='p10'>
                    <LargeLogo/>
                    </div>
                 
                </HeaderWrapper>
            </header>

        </>
    )
}
