import React from 'react'

export default function LogoCattle({ ...props }) {
    return (
        <svg width="118" height="33" viewBox="0 0 118 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.4404 31.0822C19.1176 32.2721 18.1083 32.9964 16.7836 32.9964C15.1583 32.9964 14.0339 31.836 14.0339 30.1805C14.0339 28.525 15.1175 27.3535 16.765 27.3535C17.4589 27.3535 18.0971 27.5642 18.5758 27.9411V27.4422H19.2846V29.1975H18.5758C18.2604 28.5065 17.5814 28.0778 16.8058 28.0778C15.7149 28.0778 14.9393 28.9462 14.9393 30.1546C14.9393 31.363 15.7557 32.2536 16.8392 32.2536C17.5814 32.2536 18.446 31.7621 18.7243 30.6831L19.4441 31.0785L19.4404 31.0822Z" fill="#EEEAD9" />
            <path d="M22.9434 30.986L22.5166 32.224H23.1883V32.9076H21.0212V32.224H21.6817L23.1846 28.0555H22.5389V27.4458H25.3442V28.0555H24.6985L26.1754 32.224H26.7914V32.9076H24.6429V32.224H25.3219L24.9175 30.986H22.9471H22.9434ZM23.9787 28.0555H23.9044L23.1475 30.3504H24.706L23.9787 28.0555Z" fill="#EEEAD9" />
            <path d="M32.5541 29.5226H31.875V28.1295H30.7432V32.2277H31.4631V32.9113H29.1996V32.2277H29.9195V28.1295H28.7877V29.5226H28.1086V27.4458H32.5541V29.5226Z" fill="#EEEAD9" />
            <path d="M38.6432 29.5226H37.9641V28.1295H36.8323V32.2277H37.5522V32.9113H35.2887V32.2277H36.0086V28.1295H34.8768V29.5226H34.1978V27.4458H38.6432V29.5226Z" fill="#EEEAD9" />
            <path d="M41.0366 28.1295H40.3093V27.4458H42.558V28.1295H41.8641V32.2277H43.5785V30.3689H44.3391V32.9113H40.313V32.2277H41.0403V28.1295H41.0366Z" fill="#EEEAD9" />
            <path d="M46.7994 28.1295H46.0869V27.4458H50.6362V29.0866H49.9572V28.1295H47.6231V29.7554H49.6603V30.4391H47.6231V32.2277H49.9572V31.2299H50.6362V32.9113H46.0869V32.2277H46.7994V28.1295Z" fill="#EEEAD9" />
            <path d="M61.6013 31.0822C61.2785 32.2721 60.2692 32.9964 58.9444 32.9964C57.3192 32.9964 56.1948 31.836 56.1948 30.1805C56.1948 28.525 57.2783 27.3535 58.9259 27.3535C59.6198 27.3535 60.2617 27.5642 60.7367 27.9411V27.4422H61.4492V29.1975H60.7367C60.4213 28.5065 59.7422 28.0778 58.9667 28.0778C57.8758 28.0778 57.1002 28.9462 57.1002 30.1546C57.1002 31.363 57.9166 32.2536 59.0001 32.2536C59.7422 32.2536 60.6068 31.7621 60.8851 30.6831L61.605 31.0785L61.6013 31.0822Z" fill="#EEEAD9" />
            <path d="M65.7981 27.3572C67.4456 27.3572 68.6331 28.5397 68.6331 30.1842C68.6331 31.8286 67.4456 33.0001 65.7981 33.0001C64.1506 33.0001 62.9631 31.8323 62.9631 30.1842C62.9631 28.536 64.1506 27.3572 65.7981 27.3572ZM65.7981 32.2758C66.889 32.2758 67.7277 31.3593 67.7277 30.1842C67.7277 29.009 66.889 28.0815 65.7981 28.0815C64.7072 28.0815 63.8648 28.9979 63.8648 30.1842C63.8648 31.3704 64.7035 32.2758 65.7981 32.2758Z" fill="#EEEAD9" />
            <path d="M70.915 28.1295H70.2434V27.4458H72.1359L73.5682 31.1893L75.0154 27.4458H76.9301V28.1295H76.2659V32.2277H76.9301V32.9113H74.9189V32.2277H75.5089V28.1221H75.494L73.6276 32.9667H73.468L71.6164 28.1295H71.6015V32.2277H72.1915V32.9113H70.2508V32.2277H70.9225V28.1295H70.915Z" fill="#EEEAD9" />
            <path d="M79.4274 28.1295H78.741V27.4458H81.1752C82.6966 27.4458 83.012 28.4436 83.012 29.1346C83.012 29.6889 82.7856 30.1176 82.5519 30.3504C82.1696 30.7273 81.6539 30.816 80.8709 30.816H80.2549V32.224H80.9748V32.9076H78.7447V32.224H79.4312V28.1258L79.4274 28.1295ZM80.9377 30.1582C81.947 30.1582 82.1659 29.6926 82.1659 29.1272C82.1659 28.4842 81.8023 28.1295 81.1974 28.1295H80.2512V30.1582H80.9377Z" fill="#EEEAD9" />
            <path d="M85.9175 30.986L85.4908 32.224H86.1587V32.9076H83.9917V32.224H84.6559L86.1587 28.0555H85.5131V27.4458H88.3184V28.0555H87.6727L89.1533 32.224H89.7692V32.9076H87.6208V32.224H88.2998L87.8953 30.986H85.925H85.9175ZM86.9528 28.0555H86.8786L86.1179 30.3504H87.6764L86.9491 28.0555H86.9528Z" fill="#EEEAD9" />
            <path d="M91.9732 28.1295H91.1902V27.4458H93.0641L95.439 31.6955H95.4538V28.1295H94.6857V27.4458H96.9418V28.1295H96.1329V32.9667H95.2683L92.6671 28.2994H92.6522V32.2277H93.4426V32.9113H91.1865V32.2277H91.9695V28.1295H91.9732Z" fill="#EEEAD9" />
            <path d="M100.649 30.4243L99.0161 28.1295H98.3445V27.4458H100.601V28.1295H99.9141L101.12 29.8109L102.226 28.1295H101.539V27.4458H103.729V28.1295H103.001L101.473 30.4243V32.2277H102.226V32.9113H99.9141V32.2277H100.649V30.4243Z" fill="#EEEAD9" />
            <path d="M13.6441 6.58891H10.4455V5.872C10.4455 4.40493 9.81842 3.18544 8.18943 3.18544C6.43427 3.18544 6.0261 4.5897 6.0261 6.05677C6.0261 7.77514 6.18194 8.3664 7.84433 9.02419L10.2897 9.99238C13.1135 11.053 13.6441 12.8342 13.6441 16.0787C13.6441 19.8259 12.2638 22.6048 8.12635 22.6048C5.0205 22.6048 2.67163 20.3247 2.67163 17.3278V16.1415H5.87025V17.1393C5.87025 18.296 6.49735 19.4194 8.12635 19.4194C10.2897 19.4194 10.4455 18.1408 10.4455 16.1083C10.4455 13.7358 10.1635 13.3589 8.59761 12.7381L6.30811 11.7994C3.54735 10.676 2.82748 8.86528 2.82748 6.08634C2.82748 2.71613 4.64572 0 8.31559 0C11.5142 0 13.6441 2.56092 13.6441 5.02576V6.58522V6.58891Z" fill="#EEEAD9" />
            <path d="M16.0265 0.188477H19.0989L23.2067 11.9584H23.2697L27.3441 0.188477H30.4462V22.4164H27.2476V8.89855H27.1845L24.0156 18.4512H22.4163L19.2807 8.89855H19.2177V22.4164H16.019V0.188477H16.0265Z" fill="#EEEAD9" />
            <path d="M33.5483 0.188477H36.7469V22.4164H33.5483V0.188477Z" fill="#EEEAD9" />
            <path d="M41.9493 3.18545H38.2498V0.188477H48.8475V3.18545H45.1479V22.4164H41.9493V3.18545Z" fill="#EEEAD9" />
            <path d="M50.2576 0.188477H53.4562V9.71154H57.4044V0.188477H60.603V22.4164H57.4044V12.5201H53.4562V22.4164H50.2576V0.188477Z" fill="#EEEAD9" />
            <path d="M63.6124 0.188477H73.1414V3.18545H66.8073V9.92957H72.3251V12.9266H66.8073V22.4201H63.6086V0.188477H63.6124Z" fill="#EEEAD9" />
            <path d="M75.3047 0.188477H78.5033V22.4164H75.3047V0.188477Z" fill="#EEEAD9" />
            <path d="M81.6055 0.188477H91.1345V3.18545H84.8004V9.71155H90.3182V12.7085H84.8004V19.2346H91.1345V22.4201H81.6055V0.188477Z" fill="#EEEAD9" />
            <path d="M93.2014 0.188477H96.4V19.2309H102.734V22.4164H93.2051V0.188477H93.2014Z" fill="#EEEAD9" />
            <path d="M104.801 0.188477H109.536C113.139 0.188477 115.146 2.3429 115.146 5.93484V16.33C115.146 20.4504 113.013 22.4201 109.254 22.4201H104.801V0.188477ZM108 19.4194H109.473C111.261 19.4194 111.948 18.5436 111.948 16.6404V5.93114C111.948 4.18321 111.354 3.18176 109.473 3.18176H108V19.4194Z" fill="#EEEAD9" />
        </svg>

    )
}
