import React ,{memo} from 'react'

export default function ContentContainer({children,className}) {
    return (
        <div className={['pl30 pr30   ',className].join(' ')}>
            <div className='w100'>
            {children}
            </div>
           
        </div>
    )
}
