import { TransitionLink } from 'gatsby-plugin-transition-link/components/TransitionLink';
import React ,{useCallback} from 'react';
import gsap from 'gsap'




function createPageAnimation() {

    function exitAnimation(exit, node) {
       gsap.to(node,{opacity:0,duration:0.5,ease:'ease'})
    }

    function entryAnimation(entry, node) {
    
        const tl = gsap.timeline({onStart:() =>   window.scrollTo({y:0,x:0}) })
        tl.fromTo(node,{opacity:0},{opacity:1,duration:0.75,ease:'ease'})
    }

    return {
        exit: {
            trigger: ({ exit, node }) => {exitAnimation(exit, node)},
            length: 1
        },
        entry: {
            
            trigger: ({ entry, node }) => {entryAnimation(entry, node)},
            delay:1.05
          
        },
        state:{internal:true}
    }
}

export default function Link({ children, to, ...props }) {

    const  removeTrailingSlash =  useCallback((to) => {
        if(!to?.endsWith('/') && to?.length > 1){
            return to + '/'; 
        }
        if(to === undefined){
            return '/'
        }
        return to 
    },[])  

    const pageAnimation = createPageAnimation()
    if(!to?.startsWith('/')){
        console.log(to)
        return <a target="_blank" href={to} {...props}>
                {children}
        </a>
    }else{
        return (
            <TransitionLink {...pageAnimation} to={removeTrailingSlash(to)} {...props}>
                {children}
            </TransitionLink>
        );
    }
  
}
